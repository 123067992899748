import React from 'react'

export default function Section7() {
    return (
        <div className='about_section7'>
            <div className="Title">
                <h2>
                    SPACE PROVIDERS
                </h2>
            </div>
            <div className="Content">
                <img src="./newassets/aboutUs/cowrks.png" alt="SomeImage" />
                <img src="./newassets/aboutUs/wework.png" alt="SomeImage" />
            </div>
        </div>
    )
}
