import React from "react";
import Card from "./Card";
import "./plan.css";

function createData(visits, credits, price) {return {visits, credits, price};}


const cardData = [
  createData(30, "10,000", "7,000"),
  createData(50, "15,000", "10,000"),
  createData(100, "30,000", "19,000"),
];

export default function Plans() {
  return (
    <div className="plans panel-main">
      <h3>Available Plans</h3>

      <div className="card-row">
        {cardData.map(card => {
          return (<Card data = {card} />)
        })}
      </div>
    </div>
  );
}
