import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';

function Home() {
  return (
    <div className= "Home">
      <div className="overlay"></div>
        <Section1 />
        <Section2 />
        <Section3 />
        <div id='Section4'><Section4 /></div>
        <Section5 />
    </div>
  )
}

export default Home