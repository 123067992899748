import React, {useRef} from 'react'

export default function Section6() {
  const FAQref = useRef(null);
  return (
    <div className='privacypolicy_section6' id='FAQ' ref={FAQref}>
        <div className="Title">
            <h2>
                Privacy Policy
            </h2>
        </div>
        <div className="Content">
            <h5>What Do We Do With Your Information?</h5>
            <p>When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address. When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.</p>
            <h3>Consent</h3>
            <h5>How do you get my consent?</h5>
            <p>When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>
            <h5>How do I withdraw my consent?</h5>
            <p>If after you opt­in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at sales@easydesq.com</p>
            <h3>THIRD­PARTY SERVICES</h3>
            <p>In general, the third­party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third­party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase­related transactions. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third­party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our store’s website or are redirected to a third­party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>
            <h5>Links</h5>
            <p>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements</p>
            <h3>Security</h3>
            <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
            <h3>Cookies</h3>
            <p>We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</p>
            <h3>Age of Consent</h3>
            <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
            <h3>Changes to this Privacy Policy</h3>
            <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.</p>
            <h3>How your personal information is used?</h3>
            <p>
              <ul>
                <li>We use information to contact you. We might use the information you provide us to contact you for confirmation of booking on our website / app or for other promotional purposes.</li>
                <li>We use information to respond to your requests or questions. We might use your information to answer your queries concerning our products or services.</li>
                <li>We use information to improve our products and services. We might use your information to customize your experience on our website. This may include displaying certain content based on your preferences and/or our website usage.</li>
                <li>We use information to look at site trends and customer interests. We may use your information to make our website, app and products better. We may combine information we get from you with information about you we get from third parties.</li>
                <li>We use information for marketing purposes. From time to time, we might send you information about special promotions or offers. We might also inform you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you sign up on our website and/or blog we may enroll you in our newsletter.</li>
                <li>We use information to send you transactional communications. We might send you emails or SMS about your account, any bookings you may request/undertake or any payments you may make.</li>
                <li>We use information as otherwise permitted by law, or to comply with the law or orders of law enforcement agencies or to enforce our legal rights.</li>
                <li>Your consent. By using the Site you shall be deemed to have expressly consented to our use of your information and data as aforementioned. Further the Company and our service providers may collect, transfer, store and process your information outside of your country of residence subject to applicable law. By providing us with your information or using the Site, you consent to the transfer and processing of your information.</li>
              </ul>
            </p>
            <h3>Email/SMS Opt-Out & Deletion of your Account</h3>
            <p>You can opt out of receiving our marketing emails and/or SMS by unsubscribing from our marketing lists. To stop receiving our promotional emails and/or SMS, you can also write to us at sales@easydesq.com. It may take us about ten days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your purchases.
You may ask us to delete or remove your Personal Data by contacting us at sales@easydesq.com. On receiving your deletion request, Easydesq.com may take up to 15 days to process your request. Once your personal data is deleted, you will lose access to all service. For avoidance of doubt, it is hear by clarified that all data with respect to transactions performed by you on the Platform will be retained in accordance with applicable law.</p>
<h3>Grievance Redressal Officer</h3>
<p>In the event that you have a grievance regarding our privacy policy or data usage practices, you may reach out to our Grievance Redressal Officer at the details below:</p>
<p>Grievance Redressal Officer: Mr. Dhruv Agarwal</p>
<p>Email: sales@easydesq.com</p>
        </div>
    </div>
  )
}
