import React from "react";
import { data } from "./uploadTempData";

export default function AddMultiple() {
  return (
    <div className="add-multiple">
      <div className="styled-box">
        <h6>Add multiple employees in two easy steps.</h6>
        <div className="box-row">
          <div className="box-inner">
            <h6>Step 1.</h6>
            <p>Download Excel template.</p>
            <button>Download</button>
          </div>
          <div className="box-inner">
            <h6>Step 2.</h6>
            <p>Upload Excel file.</p>
            <button>Upload</button>
          </div>
        </div>
      </div>

      <div className="styled-box">
        <h3>Past Uploads</h3>
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Excel sheet link</th>
              <th>Date of upload</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr>
                <td>{index + 1}.</td>
                <td>
                  <a href={item.link}>{item.link}</a>
                </td>
                <td>{item.date}</td>
                <td
                  style={{
                    color:
                      item.status === "Completed"
                        ? "#08B508"
                        : item.status === "Accepted"
                        ? "#0000FF"
                        : "#FF0000",
                  }}
                >
                  {item.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
