import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { BrowserRouter } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/animate.min.css';
import './styles/fontawesome.min.css';
// import './styles/style.css';
import './styles/responsive.css';
import './styles/flaticon.css';
import './styles/imagelightbox.min.css';
import './styles/owl.carousel.min.css';
import './styles/meanmenu.css';
import './styles/bootstrap.min.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);