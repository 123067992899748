import React from 'react'

function Section4() {
  return (
    <div className='Section4' id='Section4'>
      <div className="Title"><h2>Hear it from our Clients.</h2></div>
      <div className="testimonial-cards">
        <div className="card">
          <div className="details">
            <h3>Ankit Prasad</h3>
            <p>Bobble</p>
          </div>
          <div className="card_bg">
          <p>I am extremely grateful to Easydesq - Marketplace for All Flex Workspaces for their incredible assistance in finding the perfect workspace for my organization in Gurugram. Dhruv, their representative, went above and beyond to help us find the right location. The entire process was hassle-free and seamless.</p>
          </div>
        </div>
        <div className="card">
          <div className="details">
            <h3>Afnan Shakeel</h3>
            <p>Falcon</p>
          </div>
          <div className="card_bg">
          <p>Easydesq is truly a fantastic organization that provides top-notch services when it comes to workspaces. Thank you, Easydesq, for making our search so easy and convenient!</p>
          </div>
        </div>
        <div className="card">
          <div className="details">
            <h3>Sanjeev Thukral</h3>
            <p>NeurlIOT</p>
          </div>
          <div className="card_bg">
          <p>I am so grateful for Easydesq ! When I needed to find a workspace for my organization in Gurgaon, they made the process incredibly easy. Dhruv, their team member, was extremely helpful in finding the perfect location that suited our needs. The entire experience was hassle-free and efficient. Thank you, for making our search stress-free and providing excellent service!</p>
          </div>
        </div>
        <div className="card">
          <div className="details">
            <h3>Varun Gupta</h3>
            <p>Boult</p>
          </div>
          <div className="card_bg">
          <p>I can't thank Easydesq enough for their amazing support in finding the perfect workspace for my organization in Delhi and Noida. Arjun, the representative from Easydesq, went above and beyond to assist us in locating the ideal location. The entire process was hassle-free, and we are extremely satisfied with the outcome. Easydesq truly lives up to its name as a marketplace for all flex workspaces. Thank you, Easydesq, for making our search so easy and efficient!</p>
          </div>
        </div>
        <div className="card">
          <div className="details">
            <h3>Shivani Devshali</h3>
            <p>VoerEir</p>
          </div>
          <div className="card_bg">
          <p>We are thrilled to hear that your platform has made it easy for us to search for the perfect workspace for your organization in Delhi and Noida. It's wonderful that Arjun was able to assist you in finding the right location and ensure a hassle-free process. We strive to provide a seamless experience for all our users, and your feedback encourages us to continue delivering exceptional service.</p>
          </div>
        </div>
        <div className="card">
          <div className="details">
            <h3>Sanjiv Nair</h3>
            <p>HTTPOOL</p>
          </div>
          <div className="card_bg">
          <p>My experience at Easydesq, Noida was quite good. The community team and support staff is helpful, supportive and they will make sure that all the grievances of the concerned person are resolved in the best possible way. The setup of the place is beautifully designed which will boost your productivity significantly and it is equipped with all the facilities.The atmosphere is friendly and engaging. Moreover, outside view of Yamuna River and other buildings from the  building will definitely be a feel good factor. Commuting is easy since the building is situated quite near to Noida Sector-16 metro station.I would highly recommend Easydesq because it is amongst one of the best co-working spaces of in Delhi NCR area</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section4