import React from 'react'

export default function DashCard({ data }) {
    return (
        <div className='dashboard-card'>
            <h2>{data.head}</h2>
            <table>
                <tbody>
                    <tr>
                        <th>{data.field1}:</th>
                        <td>{data.ans1}</td>
                    </tr>
                    <tr>
                        <th>{data.field2}:</th>
                        <td>{data.ans2}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
