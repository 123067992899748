import React from 'react';
import { innercolumData } from './tempTableData';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"


const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key, direction) => {
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

function InnerTable({tableData}) {
    const [sortedField, setSortedField] = React.useState(null);
    const { items, requestSort, sortConfig } = useSortableData(tableData);
    
  return (
    <div className='InnerTable'>
        <table className="Tabledata" cellPadding={0} cellSpacing={0}>
        <thead className="Tableheadinner">
          <tr>
            {innercolumData[0].colList.map(item => (
              <th>
                <div className="table-head">{item.name}
                  {item.sortable &&
                    <span className="icon-box"><AiFillCaretUp className='icon' onClick={() => { requestSort(item.key, 'ascending') }} /><AiFillCaretDown className='icon' onClick={() => { requestSort(item.key, 'descending') }} /></span>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body'>
        {console.log("Items")}
        {console.log(items[0])}
          {items.map((item, idx) => {
            return (
                <tr>
                  <td><div className='table-cell'>{idx + 1}.</div></td>
                  <td><div className='table-cell'>{item.date}</div></td>
                  <td><div className='table-cell'>{item.bookingId}</div></td>
                  <td><div className='table-cell'>{item.employeeName}</div></td>
                  <td><div className='table-cell'>{item.space}</div></td>
                  <td><div className='table-cell' style={{color: item.status === "Scheduled" ? "#FFA701" : item.status === "Cancelled" ? "#FF0000" : "#08B508"}}>{item.status}</div></td>
                  <td><div className='table-cell'>{item.credits}</div></td>
                </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default InnerTable