import React, { useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "./workspace.css";
import Left from "./Left";
import Right from "./Right";
import data from "./SpaceDetailsPageResponse.json";
import data2 from "./Featured.json";
import sampleData from "./spacedetailssampleresponse.json";
import Section2 from "./Section2";
import Slider from "./Slider";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-scroll";
import axios from 'axios';

export default function Workspace() {
  const [sliderOpen, setSliderOpen] = useState(false);
  const { workspaceID } = useParams();
  const [ListingData, setListingData] = useState(sampleData);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wasLoaded, setWasLoaded] = React.useState(false);
  useEffect(() => {
    let url = "http://103.204.131.161:7080/space/details?";
    url  = url + "id=" + workspaceID;
    console.log(url);
    axios.get(url).then(res => {
      setListingData(res.data);
      setIsLoaded(true);
      console.log(res.data);
      setWasLoaded(true);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsLoaded(true);
      console.log("finally");
    });
  } , [workspaceID]);
  const optionList = [
    {
      title: "Photos",
      id: "photos",
    },
    {
      title: "Location",
      id: "location",
    },
    {
      title: "About",
      id: "about",
    },
    {
      title: "Pricing",
      id: "space-details",
    },
    {
      title: "Amenities",
      id: "amenities",
    },
  ];
  return (
    <>
    {
      isLoaded ? wasLoaded ? <div className="workspace">
      <div className="header">
        <h1 className="space_title">{ListingData.response.displayName}</h1>
        <ul className="option-list">
          {optionList.map((option, index) => (
            <li key={index}>
              <Link
                className="a"
                activeClass="active"
                smooth
                spy
                to={option.id}
              >
                {option.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="workspace_main">
        <Left details={ListingData.response} setSliderOpen={setSliderOpen} />
        <Right />
      </div>
      <div className="office_space">
        <h1>Featured Office Spaces</h1>
        <Section2 data={data2.response.spaceList} />
      </div>
      <Slider
        sliderOpen={sliderOpen}
        setSliderOpen={setSliderOpen}
        category={ListingData.response.resources}
      />
    </div> : <div>The id {workspaceID} does not exist</div> : <div>Loading...</div>
    }
    </>
  );
}
