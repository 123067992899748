import React from 'react'
import "./contact.css"
import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'

export default function Contact({setIsActive, isActive}) {
  return (
    <div id="contact" className='contact'>
      <ContactForm/>
      <ContactInfo setIsActive={setIsActive} isActive={isActive}/>
    </div>
  )
}
