import React from 'react'

function Section5() {
  return (
    <div className="feedback-area ptb-100">
        <div className="container">
          <div className="feedback-slides owl-carousel owl-theme">
            <div className="single-feedback-item">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
                suspendisse ultrices gravida.
              </p>
              <div className="client">
                <img
                  src="https://www.easydesq.com/front/img/partner-image/1.png"
                  alt="image"
                />
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                Quis ipsum suspendisse ultrices gravida. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna.
              </p>
              <div className="client">
                <img
                  src="https://www.easydesq.com/front/img/partner-image/2.png"
                  alt="image"
                />
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                Sed do eiusmod tempor incididunt ut labore et dolore magna,
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                ipsum suspendisse ultrices gravida.
              </p>
              <div className="client">
                <img
                  src="https://www.easydesq.com/front/img/partner-image/3.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Section5