import React from 'react';
import {VictoryPie} from 'victory';
import {IoCaretBack , IoCaretForward , IoCaretDown} from "react-icons/io5";
import moment from 'moment';

function Billing() {
    const sampleData = [
        { x: 1, y: 2, radius: 130 , label: "Rs20,000" },
        { x: 2, y: 3, radius: 125 , label: "Rs20,000" },
        { x: 3, y: 5, radius: 122 , label: "Rs40,000" }
      ];
      const [weekly,setweekly] = React.useState(false);
      const [today,settoday] = React.useState(moment());
      const [weekstring,setweekstring] = React.useState(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
      const [monthstring,setmonthstring] = React.useState(moment().format('MMMM')); 
      function increasehandler(){
          if(weekly){
              settoday(today.add(1,'week'));
              setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
              setmonthstring(moment(today).format('MMMM'));
          }
          else{
              settoday(today.add(1,'month'));
              setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
              setmonthstring(moment(today).format('MMMM'));
          }
      }
      function decreasehandler(){
          if(weekly){
              settoday(today.subtract(1,'week'));
              setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
              setmonthstring(moment(today).format('MMMM'));
          }
          else{
              settoday(today.subtract(1,'month'));
              setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
              setmonthstring(moment(today).format('MMMM'));
          }
      }
  return (
    <div className="Billing_Card">
      <div className="Billing_Card_Header">
        <h2>Your Billing</h2>
      </div>
      <div className="datedropdowns">
            <div className = "date-dropdown">
                <IoCaretBack  className="dropdown-icon" onClick={() => decreasehandler()}/>
                <h6>{weekly ? weekstring : monthstring}</h6>
                <IoCaretForward className="dropdown-icon" onClick={() => increasehandler()}/>
            </div>
            <div className = "toggle-dropdown">
                <h6>{weekly ? "Weekly" : "Monthly"}</h6>
                <IoCaretDown className="toggle-icon" onClick={() => setweekly(!weekly)}/>
            </div>
        </div>
      <div className ="PieChart">
        <VictoryPie
                  colorScale={["#334E6B", "#E26459", "#FF8C32"]}
                  radius={({ datum }) => 20 + datum.radius}
                  labelPosition={"centroid"}
                  labelRadius={({ innerRadius,datum }) => innerRadius + datum.radius - 50}
                  startAngle={-60}
                  endAngle={450}
                  data={sampleData}
                  style={{ labels: { fill: "white", fontSize: 10, fontWeight: "bold" } }}      
          />
        </div>
        <div className="Billing_Card_Body">
          <div className="Billing_Card_Body_Item">
            <div className ="square" style={{background: "#FF8C32", }}></div>
            <div className="Billing_Card_Body_Item_Text">
              <h6>Cabin</h6>
            </div>
          </div>
          <div className="Billing_Card_Body_Item">
            <div className ="square" style={{background: "#E26459", }}></div>
            <div className="Billing_Card_Body_Item_Text">
              <h6>Desk</h6>
            </div>
          </div>
          <div className="Billing_Card_Body_Item">
            <div className ="square" style={{background: "#334E6B", }}></div>
            <div className="Billing_Card_Body_Item_Text">
              <h6>Meeting Rooms</h6>
            </div>
          </div>
        </div>


    </div>
  )
}

export default Billing