import React, {useState} from 'react'

export default function OTPvalidation({handleChange, otp, handleSubmit}) {
  
  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }
  }

  return (
    <div className='otp-form'>
      <p>Please verify your mobile number and we will callback shortly.</p>
      <form action="" onSubmit={handleSubmit}>
        <h3>OTP Verification</h3>
        <h5>Please enter the OTP received on your mobile number.</h5>
        <div className="form-group">
        <div className="input-row">
          <input type="number" tabIndex={1} name='opt1' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp1} onChange={handleChange} id="" />
          <input type="number" tabIndex={2} name='opt2' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp2} onChange={handleChange} id="" />
          <input type="number" tabIndex={3} name='opt3' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp3} onChange={handleChange} id="" />
          <input type="number" tabIndex={4} name='opt4' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp4} onChange={handleChange} id="" />
          <input type="number" tabIndex={5} name='opt5' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp5} onChange={handleChange} id="" />
          <input type="number" tabIndex={6} name='opt6' min={0} max={9} maxLength={1} onKeyUp={e => {inputFocus(e)}} value={otp.otp6} onChange={handleChange} id="" />
        </div>
        <p>Resent OTP</p>
        </div>
        <button type="submit">Verify</button>
      </form>
    </div>
  )
}
