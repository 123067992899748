import React from 'react'
import './contact.css'

export default function ContactInfo({setIsActive, isActive}) {
  return (
    <div className="contact_info">
        <div className="info_group">
          <h4>Call Us</h4>
          <p>Get in touch directly with our team.</p>
          <p><a href="tel:+917788990255"><img src="/newassets/contact/call.png" alt="" /> +91 77889 90255</a></p>
        </div>
        <div className="info_group">
          <h4>Email Us</h4>
          <p>Write to us regarding your queries, bookings, etc.</p>
          <p><a href="mailto:sales@easydesq.com"><img src="/newassets/contact/email.png" alt="" /> sales@easydesq.com</a></p>
        </div>
        <div className="info_group">
          <h4>Request Callback</h4>
          <p>Need quick assistance, request a callback.</p>
          <p><a href="#" onClick={() => {setIsActive(!isActive)}}><img src="/newassets/contact/message-circle.png" alt="" />request a callback</a></p>
        </div>
        <div className="info_group">
          <h4>FAQs</h4>
          <p>Check out commonly asked questions</p>
          <p><a href="/about#FAQ"><img src="/newassets/contact/faq.png" alt="" /> FAQ section</a></p>
        </div>
      </div>
  )
}
