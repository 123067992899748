import React from "react";
import "./membership.css";
import { seats, addOns, tableData } from "./data.js";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

function Membership() {
  const [currentindex, setCurrentIndex] = React.useState(0);
  const [currentRows, setCurrentRows] = React.useState(tableData.slice(0, 3));

  const incrementhandler = () => { 
    console.log(currentindex);
    if(currentindex < tableData.length - 3) {
      setCurrentIndex(currentindex + 1)
    };
  };
  const decrementhandler = () => {
    if(currentindex > 0) {
      setCurrentIndex(currentindex - 1) 
    }; 
  }

  React.useEffect(() => { 
    setCurrentRows(tableData.slice(currentindex, currentindex + 3))
  }
  , [currentindex]);  
    
  return (
    <div className="panel-main membership-box">
      <div className="styled-box">
        <h3>Your Membership</h3>
        <button className="export">
          Export <img src="/newassets/Analytics/excel-icon.png" alt="" />
        </button>
        <table className="membership-table">
          <thead>
            <div className="icon-container">
              <AiFillCaretLeft className="icon" onClick={() => decrementhandler()} />
              <AiFillCaretRight className="icon" onClick={() => incrementhandler()} />
            </div>
            <tr>
              <th></th>
              {currentRows.map((row, index) => {
                return <th key={index} className="table-head" >{row.Name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr className="table-divider">
              <td colSpan={4}>Seats</td>
            </tr>
            {seats.map((seat, index) => {
              return (
                <tr key={index}>
                  <td>{seat.label}</td>
                  {currentRows.map((row, index) => {
                    return (
                      <td key={index}>
                        {row[seat.key] == null ? "" : row[seat.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr className="table-divider">
              <td colSpan={4}>Add-Ons</td>
            </tr>
            {addOns.map((addOn, index) => {
              return (
                <tr key={index}>
                  <td>{addOn.label}</td>
                  {currentRows.map((row, index) => {
                    return (
                      <td key={index}>
                        {row[addOn.key] == null ? "" : row[addOn.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Membership;
