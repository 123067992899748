export const columData = [
    {
        cols: 6,
        colList: [
            {
                name: "S.No.",
                sortable: false,
                key: null
            },
            {
                name: "Space",
                sortable: false,
                key: null
            },
            {
                name: "City",
                sortable: true,
                key: "city"
            },
            {
                name: "Credits Used",
                sortable: true,
                key: "credits"
            },
            {
                name: "Total Visits",
                sortable: true,
                key: "visits"
            },
            {
                name: "View",
                sortable: false,
                key: null
            }
        ]
    }
]

export const innercolumData = [
    {
        cols: 6,
        colList: [
            {
                name: "S.No.",
                sortable: false,
                key: null
            },
            {
                name: "Date",
                sortable: false,
                key: null
            },
            {
                name: "Booking Id",
                sortable: false,
                key: null
            },
            {
                name: "Employee",
                sortable: false,
                key: null
            },
            {
                name: "Space Type",
                sortable: true,
                key: 'space'
            },
            {
                name: "Status",
                sortable: true,
                key: 'status'
            },
            {
                name: "Credits Used",
                sortable: true,
                key: 'credits'
            }
        ]
    }
]


export const tempData = [
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Platina Towers",
        city: "Gurgaon",
        credits: 400,
        visits: 20,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Completed",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Cancelled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Platina Towers",
        city: "Gurgaon",
        credits: 400,
        visits: 20,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Platina Towers",
        city: "Gurgaon",
        credits: 400,
        visits: 20,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Platina Towers",
        city: "Gurgaon",
        credits: 400,
        visits: 20,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Platina Towers",
        city: "Gurgaon",
        credits: 400,
        visits: 20,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/we-work.svg",
        companyName: "WeWork- Berger Delhi one",
        city: "Noida",
        credits: 5000,
        visits: 60,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/cowork.svg",
        companyName: "CoWrk- XYZ",
        city: "Delhi",
        credits: 6000,
        visits: 70,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/cowork.svg",
        companyName: "CoWrk- XYZ",
        city: "Delhi",
        credits: 6000,
        visits: 70,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/cowork.svg",
        companyName: "CoWrk- XYZ",
        city: "Delhi",
        credits: 6000,
        visits: 70,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/cowork.svg",
        companyName: "CoWrk- XYZ",
        city: "Delhi",
        credits: 6000,
        visits: 70,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/awfis.png",
        companyName: "Awfis- Cyber City",
        city: "Gurgaon",
        credits: 850,
        visits: 40,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/awfis.png",
        companyName: "Awfis- Cyber City",
        city: "Gurgaon",
        credits: 850,
        visits: 40,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/awfis.png",
        companyName: "Awfis- Cyber City",
        city: "Gurgaon",
        credits: 850,
        visits: 40,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    },
    {
        icon: "/newassets/Analytics/awfis.png",
        companyName: "Awfis- Cyber City",
        city: "Gurgaon",
        credits: 850,
        visits: 40,
        subList: [
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            },
            {
                date: "20-06-2022",
                bookingId: "1278909221",
                employeeName: "Dhruv Agarwal",
                space: "Desk",
                status: "Scheduled",
                credits: "150"
            }
        ]
    }
]