import React, { useState } from "react";
import "./addEmployees.css";
import AddMultiple from "./AddMultiple";
import AddSingle from "./AddSingle";
import Switcher from "./Switcher";

export default function AddEmployee() {
  const [type, setType] = useState("Multiple");
  const switchComponent = (type) => {
    if (type === "Multiple") return <AddMultiple isActive={true} />;
    else if (type === "Single") return <AddSingle isActive={true}/>;
  };
  const onSwitch = (option) => {
    setType(option);
    switchComponent(type);
  };
  return (
    <div className="panel-main add-employees">
      <Switcher activeTab={type} onSwitch={onSwitch} />
      {switchComponent(type)}
    </div>
  );
}
