import React, { useState, useEffect } from 'react';
import data from "./Spaces.json";
import Card from '../Card/Card';
import OwlCarousel from 'react-owl-carousel';
import "../Listings/Carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import CardCarousel , {CarouselItem} from './CardCarousel';


import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react';


function Section3() {
  return (
    <div className="Section3">
      <div className="Title">
        <h2>Our Featured Office Spaces</h2>
      </div>
      <div className="Content row">
        <Swiper
        loop={true}
        slidesPerView={1}
        breakpoints={{
          768:{
            slidesPerView: 3
          }
        }}>
          {data.response.spaceList.map((x, i) => (
            <SwiperSlide className="col-md-4 col-sm-12 item">
              <Card key={x.id + i} data={x} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Section3