import React from 'react';
import "./Modal.css";
import data from "./locations.json";
import { useState } from 'react';
import { useEffect } from 'react';
import { CgClose } from "react-icons/cg";






function Modal(props ) {
  const [Response, setresponse] = useState(data.response);
  return (
    <div className="MODALBODY">
      <CgClose className="close" onClick={()=>props.close()}/>
      <ul className="features-list">
        <li className='CARD'>
          <a href="https://www.easydesq.com/city/gurgaon">
            <div className="locations">
              <button type="button">
                <img
                  alt="'Office Space htmlFor Rent near me"
                  src="./newassets/modal/nearme.png"
                />{" "}
              </button>
              <span>NEAR ME</span>
            </div>
          </a>
        </li>
        {Response.filters[0].values.map((location) => {
          return (
            <li className='CARD'>
              <a href={"/Listings/" + location.name}>
                <div className="locations">
                  <button type="button">
                    <img
                      alt="'Office Space htmlFor Rent near me"
                      src={location.icon}
                    />{" "}
                  </button>
                  <span>{location.name.toUpperCase()}</span>
                </div>
              </a>

            </li>
          );
        })}
        {/*
                  <li className='CARD col-md-4 col-6'>
                    <a href="https://www.easydesq.com/city/gurgaon">
                      <div className="locations">
                        <button type="button">
                          <img
                            alt="'Office Space htmlFor Rent near me"
                            src="./newassets/modal/Gurugram.png"
                          />{" "}
                        </button>

                        <span>Gurugram</span>
                      </div>
                    </a>
                  </li>
                  
                  <li className='CARD col-md-4 col-6'>
                    <a href="https://www.easydesq.com/city/noida">
                      <div className="locations">
                        <button type="button">
                          <img
                            alt="'Office Space htmlFor Rent near me"
                            src="./newassets/modal/Noida.png"
                          />{" "}
                        </button>

                        <span>Noida</span>
                      </div>
                    </a>
                  </li>

                  <li className='CARD col-md-4 col-6'>
                    <a href="https://www.easydesq.com/city/bangalore-">
                      <div className="locations">
                        <button type="button">
                          <img
                            alt="'Office Space htmlFor Rent near me"
                            src="./newassets/modal/Bangalore.png"
                          />{" "}
                        </button>

                        <span>Bangalore</span>
                      </div>
                    </a>
                  </li>

                  <li className='CARD col-md-4 col-6'>
                    <a href="https://www.easydesq.com/city/mumbai">
                      <div className="locations">
                        <button type="button">
                          <img
                            alt="'Office Space htmlFor Rent near me"
                            src="./newassets/modal/Mumbai.png"
                          />{" "}
                        </button>

                        <span>Mumbai</span>
                      </div>
                    </a>
                  </li> */}
      </ul>
    </div>
  )
}

export default Modal