import React from 'react'

function Chatform({ contactData, handleInput, handleSubmit }) {
    return (
        <div className='chatbox_form'>
            <p>Please enter your details to request a call back.</p>
            <form action="" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input type="text" name="name" autoComplete='off' className='form-input' id="name" value={contactData.name} onChange={handleInput} required placeholder='Name *' />
                </div>
                <div className="form-group">
                    <input type="text" name="mobile" autoComplete='off' className='form-input' id="mobile" required placeholder='Mobile Number *' onChange={handleInput} value={contactData.phone} />
                </div>
                <div className="form-group">
                    <input type="email" name="email" autoComplete='off' className='form-input' id="email" placeholder='Email' onChange={handleInput} value={contactData.email} />
                </div>
                <div className="form-group">
                    <textarea rows={3} type="text" autoComplete='off' name="note" className='form-input' id="note" placeholder='Message' onChange={handleInput} value={contactData.Note} />
                </div>
                <button type="submit">Request a Callback</button>
            </form>
        </div>
    )
}

export default Chatform