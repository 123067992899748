import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Section1 from './Section1';
import Section2 from './Section2';
import "./Listings.css";
import data from "./Listing_details.json";
import data2 from "./newListing.json"
import Dropdown from './Dropdown';
import axios from "axios";


function Listings(props) {
    const { listingid } = useParams();
    const [listing, setListing] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [wasLoaded, setWasLoaded] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const pageNumberhandler = (x) => {
      let pagenum = pageNumber + x;
      if(pagenum > 0 && pagenum <= totalPages) {
        setPageNumber(pagenum);
      }
      console.log(pagenum);
      console.log(totalPages);
    }
    useEffect(() => {
      let url = "http://103.204.131.161:7080/space/listings?";
      let params = {
        city: listingid.toLowerCase(),
        pageNumber: pageNumber,
        pageSize: 9,
      };
      url  = url + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      console.log(url);
      axios.get(url).then(res => {
        setListing(res.data);
        setIsLoaded(true);
        console.log("Listings are here")
        console.log(res.data.response);
        if(res.data.response.pagination.totalResults>0){
          setWasLoaded(true);
          setTotalPages(res.data.response.pagination.totalNumberOfPages);
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoaded(true);
        console.log("finally");
      });
    } , [listingid, pageNumber]);
    // This will be used for listing id which will in turn be used for the API call

    
  return (
    <>
    {
      isLoaded ? wasLoaded ? <div className="Listings">
      {/*Section1 will have title as well as all dropdowns which will be replaced by the filters*/}
      <Section1 data={data.response.filters}/>
      <Section2 data={listing.response.spaces} />
      <nav className="ListingPagination" aria-label="Page navigation example">
        <ul className="pagination justify-content-end">
          <li className={ pageNumber == 1 ? "page-item disabled" : "page-item"} onClick={() => pageNumberhandler(-1)}>
            <a className="page-link" href="#" tabindex="-1">Previous</a>
          </li>
          <li className="page-item"><a class="page-link" href="#">{pageNumber}</a></li>
          <li className={ pageNumber == totalPages ? "page-item disabled" : "page-item"} onClick={() => pageNumberhandler(1)}>
            <a className="page-link" href="#">Next</a>
          </li>
        </ul>
      </nav>
      {console.log(listingid)}
      </div> : <div>No Results Found</div> : <div>Loading...</div>
    }
    </>
  )
}

export default Listings;