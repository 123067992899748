import React from 'react'
import {HiMenuAlt3} from 'react-icons/hi';
import Dropdown from './Dropdown';
import { BsCheckLg } from "react-icons/bs";
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { FiChevronDown } from "react-icons/fi"


const capitalize = str => {
  const strList = str.toLowerCase().split('_');
  for (var i = 0; i < strList.length; i++) {
      strList[i] = strList[i].charAt(0).toUpperCase() + strList[i].substring(1);
  }
  return strList.join(' ');
};

function Section1(props) {
  const [active,setactive] = React.useState(false);
  const close = () => {
    setactive(false);
  }
  const updatedata = (data) => {
    setdata(data);
  }
  const [filterdata,setdata] = React.useState(props.data);
  return (
    <div className='listings_section1'>
      <div className="Title">
        <h1>Office Spaces</h1>
        <HiMenuAlt3 className="MenuBar" onClick={() => setactive(true)}/>
        </div>
        {active?<Dropdown data={filterdata} close={close} />:null}
        <div className="Dropdowns">
        <ul>
          {
            filterdata.map((filter) => {
              return(
                <li className="dropdown show">
                  <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{filter.displayName} <FiChevronDown/></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {
                      filter.type == "CONTINUOUS" ? 
                      <div className="dropdown-item">
                        <Slider
                        aria-label="Always visible"
                        defaultValue={filter.active==null? filter.minValue : filter.active}
                        step={10}
                        marks={null}
                        min = {filter.minValue}
                        max = {filter.maxValue}
                        color='secondary'
                        className="slider"
                        />
                        <MuiInput
                          className="input"
                          value={filter.active==null? filter.minValue : filter.active}
                          size="medium"
                          inputProps={{
                            steps:1,
                            min: filter.minValue,
                            max: filter.maxValue,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                        </div> : filter.values.map((value) => {
                        return(
                          value.active ? <div className="dropdown-item active"><p>{capitalize(value.name)}</p><BsCheckLg className='check-icon' /></div> : <div className="dropdown-item"><p>{capitalize(value.name)}</p></div >
                        )
                      })
                    }
                  </div>
                </li>
              )
            })
          }
          </ul>
        </div>
    </div>
  )
}

export default Section1