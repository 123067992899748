import React from "react";

export default function Section2() {
  return (
    <div className="about_section2">
      <h1>About Us</h1>
      <div className="about-banner">Discover the perfect workspace with Easydesq! We are here to revolutionise your search for office space. With transparency, ease, and a 360-degree market overview, we make finding your ideal workspace hassle-free. Our vetted flex space providers offer various service level agreements, ensuring your commercial and legal interests are safeguarded. Book your next workspace with us and experience the difference today!</div>
    </div>
  );
}
