import React from 'react'
import './signin.css'

export default function SignUp() {
  return (
    <div className='main-container'>
        <div className="login-card">
            <h4>Sign Up</h4>
            <p>Hey, Welcome to EasyDesq! Sign Up and manage your Space with ease.</p>
            <form action="" autoComplete="off">
                <div className="form-group-2">
                    <label htmlFor="name">Name *</label>
                    <input type="text" required name="name" id="name" placeholder='Enter Name'/>
                </div>
                <div className="form-group-2">
                    <label htmlFor="email">Email *</label>
                    <input type="email" required name="email" id="email" placeholder='Enter Email'/>
                </div>
                <div className="form-group-2">
                    <label htmlFor="name">Mobile Number *</label>
                    <input type="text" required name="phone" id="phone" placeholder='Enter Mobile Number'/>
                </div>
                <div className="form-group-2">
                    <label htmlFor="password">Create Password</label>
                    <input type="password" required name="password" id="password" placeholder='Enter New Password'/>
                </div>
                <div className="form-group-2">
                    <label htmlFor="password">Confirm Password</label>
                    <input type="password" required name="password" id="password" placeholder='Enter Password'/>
                </div>
                <button type="submit">Create Account</button>
            </form>
            <p className='end'>Already a User? <a href="/login">Login</a></p>
        </div>
    </div>
  )
}
