import React from 'react';
import CustomCarousel from './Carousel';

function ListingCard(props) {
    let name = props.data.displayName.split(" - ");
    return (
        <div className="col-md-4">
            <div className="card_image">
                <CustomCarousel images={props.data.resources} />
            </div>
            <div className="card_info row">
                <div className="card_title col-10">
                    <h4>{name[0]}</h4>
                    <h5>{name[1]}</h5>
                </div>
                <div className="card_location row">
                    <div className="col-9">
                        <div className="location_data row">
                            <div className="location_icon col-1">
                                <img src={props.data.location.nearestMetroStations[0].metroStation.metroLineList[0].icon.url} alt="" />
                            </div>
                            <div className="location_text col-10">
                                <p>{props.data.location.nearestMetroStations[0].metroStation.name}</p>
                                <p>{props.data.location.nearestMetroStations[0].metroStation.metroLineList[0].name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <p>{props.data.location.nearestMetroStations[0].distance}m away</p>
                    </div>
                </div>
                {
                    console.log("THIS here")
                }
                {
                    console.log(props.data.subSpaces[0])
                }
                {
                    console.log(props.data.subSpaces[1])
                }
                {
                    console.log(props.data.subSpaces[2])
                }
                <div className="card_price col-10">
                    <div className="price_data row">
                        <div className="data col-5">
                            
                            <p>{props.data.subSpaces[0].subSpaceType.split("_")[0] + " " + props.data.subSpaces[0].subSpaceType.split("_")[1]}</p>
                            <p><b>₹{props.data.subSpaces[0].leasingOptions[0].seatingComponents[0].pricePerUnit}</b>/<img src="/newassets/person-icon-blue.png" alt="" />/{props.data.subSpaces[0].leasingOptions[0].durationType.toLowerCase()}</p>
                        </div>
                        {
                            props.data.subSpaces[1] ? <div className="data col-5">
                            <p>{props.data.subSpaces[1].subSpaceType.split("_")[0] + " " + props.data.subSpaces[1].subSpaceType.split("_")[1]}</p>
                            <p><b>₹{props.data.subSpaces[1].leasingOptions[0].seatingComponents[0].pricePerUnit}</b>/<img src="/newassets/person-icon-blue.png" alt="" />/{props.data.subSpaces[1].leasingOptions[0].durationType.toLowerCase()}</p>
                        </div> : null
                        }
                    </div>
                </div>
                <div className='card_button col-10'>
                    <a className="TEXT" href={"/workspace/" + props.data.id}>
                        <h2>Explore Space</h2>
                        <i className="fa fa-angle-right" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ListingCard