import React from 'react';
import { VictoryBar, VictoryChart , VictoryTheme, VictoryAxis, VictoryLine, VictoryTooltip , VictoryVoronoiContainer } from 'victory';
import {IoCaretBack , IoCaretForward , IoCaretDown} from "react-icons/io5";
import moment from 'moment';

const weeklyData=[
    { x: "Monday", y: 15 },
    { x: "Tuesday", y: 27 },
    { x: "Wednesday", y: 29 },
    { x: "Thursday", y: 18 },
    { x: "Friday", y: 22 },
    { x: "Saturday", y: 7 },
    { x: "Sunday", y: 2 }
  ];
const monthlyData = [{x:1 , y: 10},
{x:2 , y: 10 },
{x:2 , y: 18 },
{x:4 , y: 20},
{x:5 , y: 20},
{x:6 , y: 14},
{x:7 , y: 25},
{x:8 , y: 30},
{x:9 , y: 10},
{x:10 , y: 10},
{x:11 , y: 26},
{x:12 , y: 12},
{x:13 , y: 28},
{x:14 , y: 30},
{x:15 , y: 10},
{x:16 , y: 10},
{x:17 , y: 10},
{x:18 , y: 10},
{x:19 , y: 10},
{x:20 , y: 20},
{x:21 , y: 26},
{x:22 , y: 12},
{x:23 , y: 28},
{x:24 , y: 30},
{x:25 , y: 10},
{x:26 , y: 10},
{x:27 , y: 10},
{x:28 , y: 10},
{x:29 , y: 10},
{x:30 , y: 20},
]



function Visits() {
    const [weekly,setweekly] = React.useState(false);
    const [today,settoday] = React.useState(moment());
    const [weekstring,setweekstring] = React.useState(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
    const [monthstring,setmonthstring] = React.useState(moment().format('MMMM')); 
    function increasehandler(){
        if(weekly){
            settoday(today.add(1,'week'));
            setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
            setmonthstring(moment(today).format('MMMM'));
        }
        else{
            settoday(today.add(1,'month'));
            setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
            setmonthstring(moment(today).format('MMMM'));
        }
    }
    function decreasehandler(){
        if(weekly){
            settoday(today.subtract(1,'week'));
            setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
            setmonthstring(moment(today).format('MMMM'));
        }
        else{
            settoday(today.subtract(1,'month'));
            setweekstring(moment(today).format('MMMM') + " " + moment(today).startOf('week').format('DD') + " - " + moment(today).endOf('week').format('DD'));
            setmonthstring(moment(today).format('MMMM'));
        }
    }


  return (
    <div className ="Visits_Card">
        <div className="Visits_Card_Header">
            <h2>Your Visits</h2>
        </div>
        <div className="datedropdowns">
            <div className = "date-dropdown">
                <IoCaretBack  className="dropdown-icon" onClick={() => decreasehandler()}/>
                <h6>{weekly ? weekstring : monthstring}</h6>
                <IoCaretForward className="dropdown-icon" onClick={() => increasehandler()}/>
            </div>
            <div className = "toggle-dropdown">
                <h6>{weekly ? "Weekly" : "Monthly"}</h6>
                <IoCaretDown className="toggle-icon" onClick={() => setweekly(!weekly)}/>
            </div>
        </div>
        <div className="Victory_Chart">
            { weekly ? <VictoryChart
            height={350}
            domainPadding={30}>   
                    <VictoryAxis dependentAxis
                        tickFormat={(tick) => `${tick}`}
                        label = "Employees"
                        style={{ 
                            axisLabel: {fontSize: 20, padding: 30, fill: "#112B46", fontWeight: "bold"},
                            grid: { stroke: "grey", strokeWidth: 1 } }}
                    />
            <VictoryAxis
                style={{ tickLabels: {angle :330 , transform: 'translate(-13, 10)' }}}
            />
            <VictoryBar
                style={{ data: { fill: "#FF8C32" } }}
                data={weeklyData}
            />
            </VictoryChart> : 
            
            <VictoryChart
            height={350}
            domainPadding={30}
            containerComponent={
                <VictoryVoronoiContainer voronoiDimension="x"
                labels={({ datum }) => datum.x + " July" + "\n" + datum.y + " Employees"}
                  labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{fill: "white"}}/>}
                />
              }
            >   
                    <VictoryAxis dependentAxis

                        tickFormat={(tick) => `${tick}`}
                        label="Employees"
                        style={
                            {   
                                axisLabel: {fontSize: 20, padding: 30, fill: "#112B46", fontWeight: "bold"},
                                grid: { stroke: "grey", strokeWidth: 1 } }}
                    />
            <VictoryAxis
                style={{ tickLabels: {angle :330 , transform: 'translate(-13, 10)' }}}
            />
            <VictoryLine
                style={{
                data: { stroke: "#FF8C32" },
                parent: { border: "1px solid #ccc"}
                }}
                data = {monthlyData}
                //labels={({ datum }) => datum.x + "\n" + datum.y + " employees"}
                // labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
            />
            </VictoryChart> }
        </div>
    </div>
  )
}

export default Visits