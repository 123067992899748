import React from 'react'
import './Card.css'
import CustomCarousel from './CustomCarousel'

export default function Card(props) {
    let name = props.data.displayName.split(" | ");
    return (
        <div className="spaceCard">
            <div className="card_image">
                <CustomCarousel images={props.data.resources} />
            </div>
            <div className="card_info row">
                <div className="card_title col-10">
                    <h4>{name[0]}</h4>
                    <h5>{name[1]}</h5>
                </div>
                <div className="card_location row">
                    <div className="col-9">
                        <div className="location_data row">
                            <div className="location_icon col-1">
                                <img src={props.data.location.nearestMetroStations[0].metroLineList[0].icon.url} alt="" />
                            </div>
                            <div className="location_text col-10">
                                <p>{props.data.location.nearestMetroStations[0].name}</p>
                                <p>{props.data.location.nearestMetroStations[0].metroLineList[0].name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <p>130m away</p>
                    </div>
                </div>
                <div className="card_price col-10">
                    <div className="price_data row">
                        <div className="data col-5">
                            <p>Desk</p>
                            <p><b>₹13,000</b>/<img src="/newassets/person-icon-blue.png" alt="" />/month</p>
                        </div>
                        <div className="data col-5">
                            <p>Cabin</p>
                            <p><b>₹13,000</b>/<img src="/newassets/person-icon-blue.png" alt="" />/month</p>
                        </div>
                    </div>
                </div>
                <div className='card_button col-10'>
                    <a className="TEXT" href={"/workspace/" + props.data.id}>
                        <h2>Explore Space</h2>
                        <i className="fa fa-angle-right" />
                    </a>
                </div>
            </div>
        </div>
    )
}
