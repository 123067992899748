import React from 'react'

export default function Success() {
  return (
    <div className='success'>
      <img src='/newassets/success.png' />
      <h5>Thank you for verifying your number!! <br />
        You will recieve a call back shortly.</h5>
    </div>
  )
}
