import React, { useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function AddSingle() {
  const AccessOptions = ["Desk", "Cabin", "Meeting Rooms"];
  const AddOnsOptions = ["Parking", "N/A", "Others"];
  const animatedComponents = makeAnimated();
  // STATES
  const [EmpData, setEmpData] = useState({
    name: "",
    email: "",
    monthly_limit: null,
    daily_limit: null,
    access: { desk: false, cabin: false, meeting_room: false },
    add_ons: { others: false, na: false, parking: false },
  });
  const [records, setRecords] = useState([]);
  const [selectedAccess, setAccess] = useState([false, false, false]);
  const [selectedAddOns, setAddOns] = useState([false, false, false]);
  // HANDLER FUNCTIONS
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    setEmpData({ ...EmpData, [name]: value });
  };
  const handleAccess = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log(name, value);
    const newAccess = { ...EmpData.access, [name]: value };
    setEmpData({ ...EmpData, access: newAccess });
  };
  const handleAddons = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    const newAddOns = { ...EmpData.add_ons, [name]: value };
    setEmpData({ ...EmpData, add_ons: newAddOns });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRecord = { ...EmpData, id: new Date().getTime.toString() };
    setRecords([...records, newRecord]);
    setEmpData({
      name: "",
      email: "",
      monthly_limit: null,
      daily_limit: null,
      access: { desk: false, cabin: false, meeting_room: false },
      add_ons: { others: false, na: false, parking: false },
    });
  };

  return (
    <div className="add-single">
      <div className="styled-box">
        <form action="" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Employee Name *</label>
            <div className="input-group row">
              <div className="col-md-4">
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  className="form-input"
                  id="name"
                  value={EmpData.name}
                  onChange={handleInput}
                  required
                  placeholder="Enter Name"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Work Email *</label>
            <div className="input-group row">
              <div className="col-md-4">
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  className="form-input"
                  id="email"
                  required
                  placeholder="Enter Email"
                  onChange={handleInput}
                  value={EmpData.email}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="credits">Credits Assigned *</label>
            <div className="credits row">
              <div className="subgroup col-md-4">
                <label htmlFor="monthly_limit">Monthly Limit</label>
                <input
                  type="number"
                  name="monthly_limit"
                  id="monthly_limit"
                  onChange={handleInput}
                  value={EmpData.monthly_limit}
                  placeholder="Enter limit"
                />
              </div>
              <div className="subgroup col-md-4">
                <label htmlFor="daily_limit">Daily Limit</label>
                <input
                  type="number"
                  name="daily_limit"
                  id="daily_limit"
                  onChange={handleInput}
                  value={EmpData.daily_limit}
                  placeholder="Enter limit"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="access">Access Type *</label>
            <div className="access row">
              <div className="subgroup row">
                <FormControlLabel
                  label="Desk"
                  control={
                    <Checkbox
                      checked={EmpData.access.desk}
                      onChange={handleAccess}
                      name="desk"
                    />
                  }
                />
                <FormControlLabel
                  label="Cabin"
                  control={
                    <Checkbox
                      checked={EmpData.access.cabin}
                      onChange={handleAccess}
                      name="cabin"
                    />
                  }
                />
                <FormControlLabel
                  label="Meeting Room"
                  control={
                    <Checkbox
                      checked={EmpData.access.meeting_room}
                      onChange={handleAccess}
                      name="meeting_room"
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="addOns">Add Ons *</label>
            <div className="addOns row">
              <div className="subgroup row">
                <FormControlLabel
                  label="Parking"
                  control={
                    <Checkbox
                      checked={EmpData.add_ons.parking}
                      onChange={handleAddons}
                      name="parking"
                    />
                  }
                />
                <FormControlLabel
                  label="N/A"
                  control={
                    <Checkbox
                      checked={EmpData.add_ons.na}
                      onChange={handleAddons}
                      name="na"
                    />
                  }
                />
                <FormControlLabel
                  label="Others"
                  control={
                    <Checkbox
                      checked={EmpData.add_ons.others}
                      onChange={handleAddons}
                      name="others"
                    />
                  }
                />
              </div>
            </div>
          </div>
          <button type="submit">Add Employee</button>
        </form>
      </div>
    </div>
  );
}
