import React from 'react'

function Section2() {
  return (
    <div className="Section2">
        <div className="Title">
            <h2>
                Get Office Space With EASYDESQ
            </h2>
        </div>
        <div className="Content row">
            <div className='col-md-9 row'>
            <div className="col-6 col-md-3">
                <div className="Card CARD-Right">
                    <div className="Card-icon">
                        <img src="./newassets/supply.png" alt="SomeImage"/>
                    </div>
                    <div className="Card-content">
                        <h5>SUPPLY</h5>
                        <p>Giving a 360 Market Overview</p>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="Card CARD-Left">
                    <div className="Card-icon ">
                        <img src="./newassets/menu-hamburger.png" alt="SomeImage"/>
                    </div>
                    <div className="Card-content">
                        <h5>TECHNOLOGY</h5>
                        <p>Ease of finding all available options</p>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="Card CARD-Right">
                    <div className="Card-icon ">
                        <img src="./newassets/partnership.png" alt="SomeImage"/>
                    </div>
                    <div className="Card-content">
                        <h5>TRUSTED PARTNERS</h5>
                        <p>Vetted flex spaces providers ensuring Various SLAs</p>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="Card CARD-Left">
                    <div className="Card-icon ">
                        <img src="./newassets/trusted.png" alt="SomeImage"/>
                    </div>
                    <div className="Card-content">
                        <h5>PROTECTING INTERESTS</h5>
                        <p>Safeguarding Commercial and legal interests of clients</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Section2