import React, { useState } from 'react'
import { Dropdown } from './Dropdown';
export default function ContactForm() {
    const locationOptions = ["Bengaluru", "Delhi", "Noida", "Gurugram", "Mumbai"];
    const teamSizeOptions = ["1-20", "20-50", "50-100", "100+"];
    // STATES
    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        mobile: "",
        company: "",
        note: ""
    });
    const [records, setRecords] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedTeamSize, setSelectedTeamSize] = useState("");
    // HANDLER FUNCTIONS
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setContactData({ ...contactData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newRecord = { ...contactData, id: new Date().getTime.toString() }
        setRecords([...records, newRecord]);
        setContactData({ name: "", email: "", mobile: "", company: "", note: "" });
    }
    return (
        <div className="contact_form">
            <h1>Share With Us.</h1>
            <h4>Fill in the details belows and experts from our team will reach out to you at the ealiest.</h4>
            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <input type="text" name="name" autoComplete='off' className='form-input' id="name" value={contactData.fullName} onChange={handleInput} required placeholder=' ' />
                            <label htmlFor="name" className='form-label'>Name *</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <input type="text" name="mobile" autoComplete='off' className='form-input' id="mobile" required placeholder=' ' onChange={handleInput} value={contactData.phone} />
                            <label htmlFor="mobile" className='form-label'>Mobile Number *</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <input type="email" name="email" autoComplete='off' className='form-input' id="email" required placeholder=' ' onChange={handleInput} value={contactData.email} />
                            <label htmlFor="email" className='form-label'>Email *</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Dropdown selected={selectedLocation} setSelected={setSelectedLocation} optionList={locationOptions} listTitle="Location *" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <input type="text" name="company" autoComplete='off' className='form-input' id="company" required placeholder=' ' onChange={handleInput} value={contactData.companyName} />
                            <label htmlFor="company" className='form-label'>Company Name *</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <Dropdown selected={selectedTeamSize} setSelected={setSelectedTeamSize} optionList={teamSizeOptions} listTitle="Team Size *" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input type="text" autoComplete='off' name="note" className='form-input' id="note" placeholder=' ' onChange={handleInput} value={contactData.Note} />
                            <label htmlFor="note" className='form-label'>Add a note.</label>
                        </div>
                    </div>
                </div>
                <button type="submit">Get in Touch</button>
            </form>
        </div>
    )
}
