import React, {useState} from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import './slider.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper'
import {IoClose} from "react-icons/io5"

export default function Slider({category, sliderOpen, setSliderOpen}) {
  const [activeThumb, setActiveThumb] = useState()
   return (
    <div className='image-slider expanded' style={sliderOpen ? {display: "block"} : {display: "none"}}>
        <IoClose className='close' onClick={() => {setSliderOpen(false)}} />
        <Swiper
        loop={true}
        navigation={true}
        spaceBetween={10}
        grabCursor={true}
        modules={[Navigation, Thumbs]}
        thumbs={{ swiper: activeThumb }}
        slidesPerView={1}>
        {
          category.map((categoryOne, idx) => (

            <SwiperSlide key={idx}>
                <img src={categoryOne.url} />
              </SwiperSlide>
            // categoryOne.resourceList.map((image, index) => (
              
            // ))
          ))
        }
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        loop={true}
        navigation={false}
        spaceBetween={10}
        grabCursor={true}
        modules={[Navigation, Thumbs]}
        slidesPerView={4}
        className='image-slider-thumb'>
        {
          category.map((categoryOne, idx) => (
            <SwiperSlide key={idx}>
                <img src={categoryOne.url} />
              </SwiperSlide>
            // categoryOne.resourceList.map((image, index) => (
              
            // ))
          ))
        }
      </Swiper>
    </div>
  )
}
