import React from "react";

export default function About({about}) {
  return (
    <div className="about" id="about">
      <h4>About</h4>
      <div className="about-container">
        <p>{about}</p>
      </div>
    </div>
  );
}
