import React from "react";
import "./employees.css";
import EnhancedTable from "./Table";
import { IoIosAdd } from "react-icons/io";
import NewTable from "./NewTable";
import { useNavigate } from "react-router-dom";

export default function Employees({onSwitch}) {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/dashboard/add_employees`;
    navigate(path);
  };
  return (
    <div className="panel-main">
      <div className="panel-box">
        <h2>Your Employees</h2>
        <div align="right">
          <button className="add-employee" onClick={() => onSwitch("Add Employees")}>
            Add Employee <img src="/newassets/Sidebar/add_employees.svg" />
          </button>
        </div>
        <EnhancedTable />
        {/* <NewTable /> */}
      </div>
    </div>
  );
}
