import React, { useState } from 'react'
import './chatbox.css';
import Chatform from './Chatform';
import OTPvalidation from './OTPvalidation';
import Success from './Success';

export default function Chatbox({ setIsActive, isActive }) {
    // STATES
    const [step, setStep] = useState(1)
    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        mobile: "",
        note: ""
    });
    const [finalOTP, setFinalOTP] = useState("");
    const [otp, setOtp] = useState({
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
        otp6: '',
    });

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setContactData({ ...contactData, [name]: value });
    }
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setOtp({ [name]: value });
    }

    const [records, setRecords] = useState([]);
    // HANDLER FUNCTIONS

    const nextStep = () => { setStep(step + 1) };
    const handleSubmitFORM = (e) => {
        e.preventDefault();
        const newRecord = { ...contactData, id: new Date().getTime.toString() }
        setRecords([...records, newRecord]);
        setContactData({ name: "", email: "", mobile: "", note: "" });
        nextStep();
    }
    const handleSubmitOTP = () => {
        setFinalOTP(otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6);
        setOtp({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" });
        nextStep();
    }
    const pageDisplay = () => {
        if (step === 1)
            return <Chatform contactData={contactData} handleInput={handleInput} handleSubmit={handleSubmitFORM} />;
        else if (step === 2)
            return <OTPvalidation handleChange={handleChange} otp={otp} handleSubmit={handleSubmitOTP} />;
        else
            return <Success />;
    }
    return (
        <div className='chatbox-main'>
            <a onClick={() => { setIsActive(!isActive) }}>
                <div id="mybutton">
                    <button className="feedback"> <img src="http://103.204.131.161:3000/newassets/message-circle-feedback.png" /> </button>
                </div>
            </a>
            {isActive &&
                <div className='chatbox'>
                    <div className="chatbox-header">
                        <h3>Want A Call Back ?</h3>
                        <div onClick={() => { setIsActive(false) }} className="close"></div>
                    </div>
                    <div className="chatbox-container">
                        {pageDisplay()}
                    </div>
                </div>}
        </div>
    )
}
