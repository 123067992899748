import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import "./Carousel.css";


function CustomCarousel(props) {
  return (
    <div className='Carousel'>
      <div className='container-fluid' >
        <OwlCarousel items={1} className="owl-theme" loop nav margin={8} >
          {
            props.images.map((image, idx) => {
              return(
                <div className="item">
                  <img className="img" key={idx} src={image.url} />
                </div>
              )
            })
          }
        </OwlCarousel>
      </div>
    </div>
  );
}
export default CustomCarousel;


{/* <CCarousel controls indicators className="controller">
                <CCarouselItem className='control'>
                    <CImage fluid className="image" src="../newassets/image_1.png" alt="slide 1"/>
                </CCarouselItem>
                <CCarouselItem className='control'>
                    <CImage fluid className="image" src="../newassets/image_2.png" alt="slide 2" />
                </CCarouselItem>
                <CCarouselItem className='control'>
                    <CImage fluid className="image" src="../newassets/image_3.png" alt="slide 3" />
                </CCarouselItem>
                </CCarousel> */}