import React from 'react'

export default function Location({ location }) {
    return (
        <div className="location" id="location">
            <h4>Location</h4>
            <div className="location-container">
                <div className="address">
                    <h5>Address</h5>
                    <p>{location.addressLine1}{", "}{location.addressLine2}{", "}{location.city.displayName}{", "}{location.city.state.name}{"-"}{location.pinCode}</p>
                </div>
                <div className='location-details'>
                    <div className="map">
                    <iframe src={location.googlePinUrl} width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="details">
                        <div className="details-container">
                            <h5>Connectivity</h5>
                            <div className="detail-item">
                                <div className="metro-icon">
                                    <img src={location.nearestMetroStations[0].metroStation.metroLineList[0].icon.url} alt="" />
                                </div>
                                <div className="metro-details">
                                    <p>{location.nearestMetroStations[0].metroStation.name + ' Metro Station'}</p>
                                    <p>{location.nearestMetroStations[0].metroStation.metroLineList[0].name}</p>
                                    <p>{location.nearestMetroStations[0].distance + 'm Walking'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="details-container">
                            <h5>Nearby Landmark</h5>
                            {location.nearestLandmarkAreas.map(landmark => {
                                return (
                                    <div className="detail-item">
                                        <p><img src="http://103.204.131.161:3000/newassets/bullet.png" alt="" />{landmark.landmarkArea.name}</p>
                                    </div>
                                )
                            }
                            )}
                            {/* <p><img src="/newassets/ListingDetails/Restaurant.png" alt="" /> Restaurant</p>
                            <p><img src="/newassets/ListingDetails/CAFE.png" alt="" /> Cafe</p>
                            <p><img src="/newassets/ListingDetails/ATM.png" alt="" /> ATM</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
