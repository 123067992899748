export const seats = [
    {
        label: "Type of Seat",
        key: "seat_type",
    },
    {
        label: "Seats: Cabin",
        key: "seats_cabin",
    },
    {
        label: "Seats: Desk",
        key: "seats_desk",
    },
    {
        label: "Seats: Meeting Room",
        key: "seats_meeting_room",
    },
    {
        label: "Total Seating Cost",
        key: "total_seating_cost",
    },
    {
        label: "Start Date",
        key: "start_date",
    },
    {
        label: "End Date",
        key: "end_date",
    },
    {
        label: "Deposit",
        key: "deposit",
    },
    {
        label: "Due Amount",
        key: "due_amount",
    },
    {
        label : "Notice Period",
        key : "notice_period",
    },
    {
        label : "Escalation",
        key : "escalation",
    }
];


export const addOns = [
    {
        label: "Parking: 4-Wheeler",
        key: "parking_4_wheeler",
    },
    {
        label: "Parking: 2-Wheeler",
        key: "parking_2_wheeler",
    },
    {
        label: "Total Parking Cost",
        key: "total_parking_cost",
    },
    {
        label: "24*7 Monthly Cost",
        key: "24_7_monthly_cost",
    },
    {
        label: "Total Add Ons Cost",
        key: "total_addons_cost",
    }
]  

export const tableData = [
  {
    Name: "We Work - Platina Towers",
    seats_cabin: "100",
    seats_desk: "100",
    seats_meeting_room: "100",
    parking_4_wheeler: "100",
    parking_2_wheeler: "100",
  },
  {
    Name: "We Work - Forum",
  },
  {
    Name: "Co wrks - DLF Cyber City",
  },
  {
    Name: "We Work - Platina Towers",
    seats_cabin: "100",
    seats_desk: "100",
    seats_meeting_room: "100",
    parking_4_wheeler: "100",
    parking_2_wheeler: "100",
  },
  {
    Name: "We Work - Forum",
  },
  {
    Name: "Co wrks - DLF Cyber City",
  },
  {
    Name: "We Work - Platina Towers",
    seats_cabin: "100",
    seats_desk: "100",
    seats_meeting_room: "100",
    parking_4_wheeler: "100",
    parking_2_wheeler: "100",
  },
  {
    Name: "We Work - Forum",
  },
  {
    Name: "Co wrks - DLF Cyber City",
  }
];
