import React from 'react'
import "../Listings/Carousel.css";

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper'

function CustomCarousel(props) {
  return (
    <div className='Carousel'>
      <div className='container-fluid' >
        <Swiper
        loop={true}
        navigation={true}
        spaceBetween={10}
        grabCursor={true}
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        pagination={{clickable: true}}>
          {
            props.images.map((image, idx) => (
              <SwiperSlide className='item'>
                <img className="img" key={idx} src={image.url} />
              </SwiperSlide>
            ))
          }

        </Swiper>
        {/* <OwlCarousel items={1} className="owl-theme" loop nav margin={8} >
          {props.images.map((image, idx) => {
            return (
              <SwiperSlide>
                <img className="img" key={idx} src={image.url} />
                <SwiperSlide />
                )
            })
          }
        </OwlCarousel> */}
      </div>
    </div>
  );
}
export default CustomCarousel;