import React from 'react'

export default function Section1() {
  return (
    <div className='privacypolicy_section'>
        <div className="privacypolicy_col">
            <h1>Privacy matters to us</h1>
        </div>
        <div className="privacypolicy_col">
            <img src="/newassets/aboutUs/aboutUs_bg.png" alt="" />
        </div>
    </div>
  )
}
