import React from "react";

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export default function Amenities({ amenityList }) {
  return (
    <div className="amenities" id="amenities">
      <h4>Amenities</h4>
      <div className="amenity-category">
        <div className="amenity-details">
          {amenityList.map((amenity) => (
            <div className="amenity-item">
              <img src={amenity.icon.url} alt="" />
              <p>{amenity.name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* {amenityList.map(amenity => {
        if (amenity.category === "FEATURED") {
          return (
            <div className="amenity-category">
              <h5>{capitalize(amenity.category.split('_')[0])}</h5>
              <div className="featured-row">
                {amenity.amenityList.map(item => {
                  return (
                    <div className="featured-card">
                      <img src={item.resourceList[0].url} alt="" />
                      <div className="featured-card__content">
                        <h5>{item.name}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        } else {
          return (
            <div className="amenity-category">
              <h5>{capitalize(amenity.category.split('_')[0])}</h5>
              <div className="amenity-details">
                {amenity.amenityList.map(item => {
                  return (
                    <div className="amenity-item">
                      <img src={item.icon.url} alt="" />
                      <p>{item.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      })} */}
    </div>
  );
}
