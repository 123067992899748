import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import './userpanel.css'

import Dashboard from "../Dashboard/Dashboard";
import Employees from "../Your_Employees/Employees";
import AddEmployee from "../Add Employees/AddEmployee";
import Analytics from "../Analytics/Analytics";
import Membership from "../Your_Membership/membership";
import Plans from "../Plans/Plans";
import Billing from "../Billing/Billing";

export default function UserPanel() {
  const [activeTab, setActiveTab] = useState("Dashboard");

  const switchTab = (activeTab) => {
    switch (activeTab) {
      case "Dashboard":
        return <Dashboard />;
      case "Your Employees":
        return <Employees onSwitch={onSwitch}/>;
      case "Add Employees":
        return <AddEmployee />;
      case "Analytics":
        return <Analytics />;
      case "Your Membership":
        return <Membership />;
      case "Plans and Prices":
        return <Plans />;
      case "Billing Information":
        return <Billing />;
    }
  };
  const onSwitch = (option) => {
    setActiveTab(option);
    switchTab(activeTab);
  };
  return (
    <div className="user-panel">
      <Sidebar onSwitch={onSwitch} activeTab={activeTab} />
      <div className="panel-component">{switchTab(activeTab)}</div>
    </div>
  );
}
