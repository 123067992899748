import React from "react";
import "./home.css";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import Modal from "./Modal";

function Section1() {
  const [isActive, setIsActive] = React.useState(false);
  const close = () => {setIsActive(false)};
  return (
    <header className="Section1">
      <div className="header_img">
        <img
          src="./newassets/header-bg.png"
          alt="HEADER"
        />
        <div className="header_content">
          <h1>
            Marketplace for <br /> All Flex Workspaces.
          </h1>
          <p>
            Book your next workspace with EASYDESQ. <br /> Traparent and hasslefree.
          </p>
          <div className="header_search">
            <button className="btn" type="button" onClick={()=>setIsActive(true)}>
              <MdIcons.MdLocationPin className="icons"/>{" "}
              <span>Location</span>{" "}
              <AiIcons.AiFillCaretDown className="icons"/>
            </button>
            <div className="divider"></div>
            <div className="text-input">
              <FaIcons.FaSearch className="icons search-icon"/>{" "}
              <input
                type="text"
                name=""
                id=""
                placeholder="Search locations and spaces"
              />
            </div>
          </div>
        </div>
      </div>
      {
        isActive && <div className="show" id="MODAL">
        <div className="card card-body">
          <div className ="blur"></div>
          <Modal close={close} />
        </div>
      </div>
      }
    </header>
  );
}

export default Section1;
