import React from 'react'
import { tempData } from './tempTableData'


export default function AnalyticsBox({data}) {
  return (
    <div className='analytics-box'>
        <h2>{data.title}</h2>
        <data.component tableData = {tempData}/>
    </div>
  )
}
