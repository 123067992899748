import React from "react";
import SpaceDetailCard from "./SpaceDetailCard";

export default function SpaceDetails({ subListingList }) {
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <div className="space-details" id="space-details">
      <h4>Pricing</h4>
      {subListingList.map((list, idx) => (
        <SpaceDetailCard
          category={capitalize(list.subSpaceType.split("_").join(" "))}
          description={list.summary}
          price={list.leasingOptions[0].seatingComponents[0].pricePerUnit}
        />
      ))}
    </div>
  );
}
