import React from 'react'

export default function SpaceDetailCard(props) {
  return (
    <div className='SpaceDetailCard'>
        <h5>{props.category}</h5>
        <div className="details">
            <p>{props.description}</p>
            <div className="price-details">
                <p>starting from</p>
                <p><b>₹{props.price}</b>/<img src="/newassets/person-icon-orange.png" alt="" />/month</p>
            </div>
        </div>
    </div>
  )
}
