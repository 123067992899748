import React from "react";
import "./billing.css";

export default function Billing() {
  return (
    <div className="panel-main billing">
      <div className="container-card">
        <h3>Billing Information</h3>
        <div className="btn-row">
          <button>Generate Statement</button>
          <button>Generate Invoice</button>
        </div>
        <div className="details-table">
          <table>
            <tr>
              <th>Due date:</th>
              <td>1 August 2022</td>
            </tr>
            <tr>
              <th>Last Paid:</th>
              <td>2 June 2022</td>
            </tr>
            <tr>
              <th>Due amount:</th>
              <td>₹ 30,000</td>
            </tr>
            <tr>
              <th>Last paid amount:</th>
              <td>₹ 30,000</td>
            </tr>
            <tr>
              <th>Billing type:</th>
              <td>Postpaid</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
