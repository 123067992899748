import React from 'react'
import './dashboard.css'
import DashCard from './DashCard'

export default function Dashboard() {
  const companyData = [
    {
      id: 1,
      head: "Corporate Information",
      field1: "Company Name",
      field2: "Company Address",
      ans1: "Easydesq",
      ans2: "Two Horizon Center, First Floor, Golf Course Road, DLF Phase 5, Sector 43, Gurugram, Haryana-122002." 
    },
    {
      id: 2,
      head: "Admin Information",
      field1: "Admin Name",
      field2: "Email Address",
      ans1: "Dhruv Agarwal",
      ans2: "dhruv.agarwal@easydesq.com" 
    },
    {
      id: 3,
      head: "Additional Information",
      field1: "Total Credits",
      field2: "Total Add On",
      ans1: "5000",
      ans2: "300" 
    }
  ]
  return (
    <div className='dashboard panel-main' id='dashboard' >
      <div className="main-content">
      {companyData.map(data => (
        <DashCard data={data} key={data.id} />
      ))}
      </div>
    </div>
  )
}
