import React from "react";
import "./footer.css";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_row">
        <div className="footer_container">
        </div>
        <div className="footer_container">
        <h3>Follow Us</h3>
        <ul className="social_icons">
          <li><a href="https://instagram.com/easydesq?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer"><FiIcons.FiInstagram /></a></li>
          <li><a href="https://www.facebook.com/Easydesq" target="_blank" rel="noopener noreferrer"><FiIcons.FiFacebook/></a></li>
          <li><a href="https://twitter.com/easydesq" target="_blank" rel="noopener noreferrer"><FiIcons.FiTwitter /></a></li>
          <li><a href="https://www.linkedin.com/company/easydesq/" target="_blank" rel="noopener noreferrer"><FiIcons.FiLinkedin /></a></li>
        </ul>
        </div>
        <div className="footer_container">
          <h3>About Us</h3>
          <ul className="footer-quick-links">
            <li>
              <a href="http://www.easydesq.com/#Section4">Testimonials</a>
            </li>
            <li>
              <a href="http://www.easydesq.com/privacypolicy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="footer_container">
          <h3>Contact Us</h3>
          <ul className="footer-contact-info">
            <li>
              <span>
                <RiIcons.RiPhoneFill className="footer-icon" />
              </span>
              <p>
                <a href="tel: +91 77889 90255"> +91 77889 90255</a>
              </p>
            </li>
            <li>
              <span>
                <AiIcons.AiTwotoneMail className="footer-icon" />
              </span>
              <p><a href="mailto:sales@easydesq.com">sales@easydesq.com</a></p>
            </li>
            <li>
              <span>
                <MdIcons.MdLocationPin className="footer-icon" />
              </span>
              <p>
                <a href="https://goo.gl/maps/73fymB9ijKnJDYNy8" target="_blank" rel="noopener noreferrer">Two Horizon Center, First Floor, Golf Course Road, DLF Phase 5,
              Sector 43, Gurugram, Haryana-122002.</a>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_row copyright-area">
        <p>
          &copy; 2021 Easydesq. All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
