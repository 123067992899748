export const headers = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: "Employee Name",
        sortable: true,
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: "Email",
        sortable: true,
    },
    {
        id: 'credits',
        numeric: true,
        disablePadding: true,
        label: "Credits",
        sortable: true,
    },
    {
        id: 'access',
        numeric: false,
        disablePadding: false,
        label: "Access Type",
        sortable: true,
    },
    {
        id: 'group',
        numeric: false,
        disablePadding: true,
        label: "Groups",
        sortable: true,
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: true,
        label: "Role",
        sortable: true,
    },
    {   
        numeric: false,
        disablePadding: true,
        sortable: true,
        label: "Add Ons",
        id: 'addOns'
    },  
]


export const columnData = [
    {
        cols: 7,
        colList: [
            {   
                name: "Employee Name",
                sortable: true,
                key: 'name'
            },
            {
                name: "Email",
                sortable: true,
                key: 'email'
            },
            {
                name: "Credits",
                sortable: true,
                key: 'credits'
            },
            {
                name: "Access Type",
                sortable: true,
                key: 'access'
            },
            {
                name: "Groups",
                sortable: true,
                key: 'group'
            },
            {
                name: "Role",
                sortable: true,
                key: 'role'
            },
            {
                name: "Add Ons",
                sortable: true,
                key: 'addOns'
            },
        ]
    }
]
export const tempData = [
    {
        checkBox: <input type="checkbox"/>,
        name: "Devansh Verma",
        email: "devansh@easydesq.com",
        credits: 200,
        access: ["Desk"],
        group: "HR",
        role: "Super Admin",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Shreya ",
        email: "shreya@easydesq.com",
        credits: 200,
        access: ["Desk", "Cabin"],
        group: "Sales",
        role: "User",
        addOns: "N/A",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Arunima",
        email: "arunima@easydesq.com",
        credits: 200,
        access: ["Cabin"],
        group: "Marketing",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Devansh Verma",
        email: "devansh@easydesq.com",
        credits: 200,
        access: ["Desk"],
        group: "HR",
        role: "Super Admin",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Shreya ",
        email: "shreya@easydesq.com",
        credits: 200,
        access: ["Desk", "Cabin"],
        group: "Sales",
        role: "User",
        addOns: "N/A",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Arunima",
        email: "arunima@easydesq.com",
        credits: 200,
        access: ["Cabin"],
        group: "Marketing",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Devansh Verma",
        email: "devansh@easydesq.com",
        credits: 200,
        access: ["Desk"],
        group: "HR",
        role: "Super Admin",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Shreya ",
        email: "shreya@easydesq.com",
        credits: 200,
        access: ["Desk", "Cabin"],
        group: "Sales",
        role: "User",
        addOns: "N/A",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Arunima",
        email: "arunima@easydesq.com",
        credits: 200,
        access: ["Cabin"],
        group: "Marketing",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Devansh Verma",
        email: "devansh@easydesq.com",
        credits: 200,
        access: ["Desk"],
        group: "HR",
        role: "Super Admin",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Shreya ",
        email: "shreya@easydesq.com",
        credits: 200,
        access: ["Desk", "Cabin"],
        group: "Sales",
        role: "User",
        addOns: "N/A",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Arunima",
        email: "arunima@easydesq.com",
        credits: 200,
        access: ["Cabin"],
        group: "Marketing",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Devansh Verma",
        email: "devansh@easydesq.com",
        credits: 200,
        access: ["Desk"],
        group: "HR",
        role: "Super Admin",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Aditya Kumar",
        email: "Adityakumar@easydesq.com",
        credits: 200,
        access: ["Meeting Room"],
        group: "HR",
        role: "User",
        addOns: "Parking"
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Shreya ",
        email: "shreya@easydesq.com",
        credits: 200,
        access: ["Desk", "Cabin"],
        group: "Sales",
        role: "User",
        addOns: "N/A",
        included : true,
        selected : false,
    },
    {
        checkBox: <input type="checkbox"/>,
        name: "Arunima",
        email: "arunima@easydesq.com",
        credits: 200,
        access: ["Cabin"],
        group: "Marketing",
        role: "User",
        addOns: "Parking",
        included : true,
        selected : false,
    },
]