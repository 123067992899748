import React, { useState } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export default function PhotosCarousel({ category, setSliderOpen }) {
  // console.error(category);
  const [activeThumb, setActiveThumb] = useState();
  return (
    <>
      <Swiper
        loop={true}
        navigation={true}
        spaceBetween={10}
        grabCursor={true}
        modules={[Navigation, Thumbs]}
        thumbs={{ swiper: activeThumb }}
        slidesPerView={1}
      >
        {category.map((categoryOne, idx) => (
          <SwiperSlide key={idx}>
            <div className="flag">
              <h5>
                {capitalize(categoryOne.resourceCategory.split("_").join(" "))}
              </h5>
            </div>
            <img className="Carouselimage" src={categoryOne.url} />
            <div
              className="expand"
              onClick={() => {
                setSliderOpen(true);
              }}
            >
              <img src="/newassets/ListingDetails/expand.png" alt="" />
            </div>
          </SwiperSlide>

          // categoryOne.resourceList.map((image, index) => (
          //   <SwiperSlide key={index + "-" + idx}>
          //     <div className="flag">
          //       <h5>{capitalize(categoryOne.resourceCategory.split('_').join(' '))}</h5>
          //     </div>
          //     <img src={image.url} />
          //     <div className="expand" onClick={() => { setSliderOpen(true) }}>
          //       <img src="/newassets/ListingDetails/expand.png" alt="" />
          //     </div>
          //   </SwiperSlide>
          // ))

          // console.log(categoryOne)
        ))}
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        loop={true}
        navigation={false}
        spaceBetween={10}
        grabCursor={true}
        modules={[Navigation, Thumbs]}
        slidesPerView={4}
        className="image-slider-thumb"
      >
        {category.map((categoryOne, idx) => (
          <SwiperSlide key={idx}>
            <img className="Sliderimg"src={categoryOne.url} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
