import React from "react";

export default function Switcher({ onSwitch, activeTab }) {
  return (
    <div className="switch-bar">
      <ul>
        <li onClick={() => onSwitch("Single")} className={activeTab === "Single" ? 'active' : ''}>Add Employee</li>
        <li onClick={() => onSwitch("Multiple")} className={activeTab === "Multiple" ? 'active' : ''}>Add Multiple Employees</li>
      </ul>
    </div>
  );
}
