import React from "react";

export default function Card({data}) {
  return (
    <div className="plan-card">
      <div className="card-head">
        <h4><b>{data.visits}</b> Visits Plan</h4>
      </div>
      <div className="card-body">
        <p>
          {data.visits} visits + <br /> {data.credits} credits
        </p>
        <h3>₹ {data.price} <span>*</span></h3>
        <button>Buy Plan</button>
      </div>
    </div>
  );
}
