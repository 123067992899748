import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"
import { columData } from './tempTableData'
import TablePagination from '@mui/material/TablePagination';
import './table.css'
import InnerTable from './InnerTable';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default function BookedSpacesTable({tableData}) {
  const [sortedField, setSortedField] = React.useState(null);
  const { items, requestSort, sortConfig } = useSortableData(tableData);
  return (
    <div className='bookspace-table'>
      <table className="Outertable"cellPadding={0} cellSpacing={0}>
        <thead className="Tablehead">
          <tr>
            {columData[0].colList.map(item => (
              <th>
                <div className="table-head">{item.name}
                  {item.sortable &&
                    <span className="icon-box"><AiFillCaretUp className='icon' onClick={() => { requestSort(item.key, 'ascending') }} /><AiFillCaretDown className='icon' onClick={() => { requestSort(item.key, 'descending') }} /></span>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body'>
          {items.map((item, idx) => {
            return (
              <>
                <tr>
                  <td><div className='table-cell'>{idx + 1}.</div></td>
                  <td><div className='table-cell'><span><img src={item.icon} className="companyLogo" />{item.companyName} </span><button class="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapse" + idx } aria-expanded="true" aria-controls="collapseOne"><AiFillCaretDown className='icon' /></button></div></td>
                  <td><div className='table-cell'>{item.city}</div></td>
                  <td><div className='table-cell'>{item.credits}</div></td>
                  <td><div className='table-cell'>{item.visits}</div></td>
                  <td><div className='view-icon table-cell'><a href=""><img src='/newassets/Analytics/excel-icon.png' /></a> <a href=""><img src='/newassets/Analytics/adobe-icon.png' /></a></div></td>
                </tr>
                <tr className='InnerTable-Row'>
                  <td colspan="6">
                <div id={"collapse" + idx } class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <InnerTable tableData={item.subList} />
                </div>
                </td>
                </tr>
              </>
              
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
