import React from 'react'
import { useState } from 'react'
import * as AiIcons from "react-icons/ai"
export const Dropdown = (props) => {
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {setIsActive(!isActive)};
    const [isSelected, setIsSelected] = useState(false)
    return (
        <div className="dropdown">
            <div className="dropdown-select">
                <label htmlFor="team" style={isActive ? { display: 'block', top: '-2vw'} : {}}>{props.listTitle}</label>
                <div className={isActive ? 'select active':'select'} id='team' onClick={handleClick}> <span>{isSelected ? props.selected : props.listTitle}</span> {isActive ? <AiIcons.AiFillCaretUp className='icon' /> : <AiIcons.AiFillCaretDown className='icon' />}</div>
            </div>
            {isActive && (
                <div className="dropdown-list">
                    {props.optionList.map(option => (
                        <div onClick={(e) => {props.setSelected(option); setIsActive(false); setIsSelected(true)}}>
                            <label className='dropdown-label' htmlFor={option}>{option}</label>
                            <input type="radio" name="" id={option} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
