import React from 'react'
import About from './About'
import Amenities from './Amenities'
import PhotosCarousel from './Carousel'
import Location from './Location'
import SpaceDetails from './SpaceDetails';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {NavigateNext} from '@mui/icons-material';

export default function Left({details, setSliderOpen}) {
  return (
    <div className='listing-details'>
        <div className="photos" id="photos">
            <PhotosCarousel setSliderOpen={setSliderOpen}  category={details.resources}/>
        </div>
        <Breadcrumbs className="BreadCrumbs" separator={<NavigateNext fontSize="large" className="Navigateicon" />} aria-label="breadcrumb" style={{boxShadow: 'none'}}>
          <Link underline="hover" color="inherit" href="/">Home</Link>
          <Link underline="hover" color="inherit" href={"/Listings/" + details.location.city.displayName}>{details.location.city.displayName}</Link>
          <Link underline="hover" color="inherit" href="#">{details.displayName}</Link>
        </Breadcrumbs>
        <Location location={details.location}/>
        <About about={details.description}/>
        <SpaceDetails subListingList={details.subSpaces}/>
        <Amenities amenityList={details.amenities}/>
    </div>
  )
}
