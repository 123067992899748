import React from "react";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";

export default function Pagination({ rowsPerPage, totalRows, currentPage, updatePage }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="pagination">
      <button onClick={() => updatePage(-1)} disabled={currentPage === pageNumbers[0]}>
        <AiFillCaretLeft
          className="icon"
          style={{
            color: currentPage === pageNumbers[0] ? "#C1C4C9" : "#41556B",
          }}
        />
      </button>
      <div className="current">{currentPage}</div>
      <button onClick={() => updatePage(+1)} disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>
      <AiFillCaretRight
        className="icon"
        style={{
          color:
            currentPage === pageNumbers[pageNumbers.length - 1]
              ? "#C1C4C9"
              : "#41556B",
        }}
      />
      </button>
      
    </div>
  );
}
