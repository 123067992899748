import React, { useState, useRef, useEffect } from "react";
import "./sidebar.css";
import { Link } from "react-scroll";

export default function Sidebar({ onSwitch, activeTab }) {
  const [active, setActive] = useState(false);

  const panelList = [
    {
      title: "Dashboard",
      subList: [],
      id: "dashboard",
      icon: "/newassets/Sidebar/dashboard.svg",
      link: "/dashboard",
    },
    {
      title: "Employee Management",
      subList: [
        {
          title: "Your Employees",
          id: "your_employees",
          icon: "/newassets/Sidebar/Your_Employees.svg",
          link: "/dashboard/your_employees",
        },
        {
          title: "Add Employees",
          id: "add_employees",
          icon: "/newassets/Sidebar/add_employees.svg",
          link: "/dashboard/add_employees",
        },
      ],
      id: "employee_management",
      icon: "/newassets/Sidebar/Employee_Management.svg",
    },
    {
      title: "Analytics",
      subList: [],
      id: "analytics",
      icon: "/newassets/Sidebar/analytics.svg",
      link: "/dashboard/analytics",
    },
    {
      title: "Membership",
      subList: [
        {
          title: "Your Membership",
          id: "your_membership",
          icon: "/newassets/Sidebar/your_membership.svg",
          link: "/dashboard/your_membership",
        },
        {
          title: "Plans and Prices",
          id: "plans",
          icon: "/newassets/Sidebar/plans.svg",
          link: "/dashboard/plans",
        },
        {
          title: "Billing Information",
          id: "billing",
          icon: "/newassets/Sidebar/billing.svg",
          link: "/dashboard/billing",
        },
      ],
      id: "membership",
      icon: "/newassets/Sidebar/membership.svg",
    },
  ];
  return (
    <div className="dash-sidebar" id="dash-sidebar">
      <ul>
        {panelList.map((item) => {
          if (item.subList.length === 0) {
            return (
              <li onClick={() => onSwitch(item.title)}>
                <span className={activeTab === item.title ? 'active' : ''}>
                  <img src={item.icon} className={activeTab === item.title ? 'active' : ''} />
                  {item.title}
                </span>
              </li>
            );
          } else {
            return (
              <li>
                <span>
                  <img src={item.icon} />
                  {item.title}
                </span>
                <ul>
                  {item.subList.map((subItem) => {
                    return (
                      <li onClick={() => onSwitch(subItem.title)}>
                        <span className={activeTab === subItem.title ? 'active' : ''}>
                          <img src={subItem.icon} className={activeTab === subItem.title ? 'active' : ''}/>
                          {subItem.title}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
      <div className="logout-btn">
        <button>
          <span>Logout</span>
          <div></div>
          <img src="/newassets/Sidebar/logout.svg" />
        </button>
      </div>
    </div>
  );
}
