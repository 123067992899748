import { Routes, BrowserRouter, Route } from "react-router-dom";
import { useState } from "react";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  Footer,
  Navbar,
  Home,
  Contact,
  Chatbox,
  AboutUs,
  Listings,
  Workspace,
  Login,
  SignUp,
  UserPanel,
  PrivacyPolicy,
} from "./Components";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [path, setpath] = useState(window.location.pathname);
  return (
    <div>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route
              path="contact"
              element={
                <Contact isActive={isActive} setIsActive={setIsActive} />
              }
            />
            <Route path="about" element={<AboutUs />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="Listings/:listingid" element={<Listings />} />
            <Route path="workspace/:workspaceID" element={<Workspace />} />
            <Route path="/dashboard" element={<UserPanel />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Chatbox isActive={isActive} setIsActive={setIsActive} />
      {path.substring(0, 10) === "/dashboard" ? null : <Footer />}
    </div>
  );
}

export default App;
