import React, { useState, useEffect } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { columnData, tempData } from "./tempData";
import "../Analytics/table.css";
import Pagination from "./Pagination";
import { CgSearch } from "react-icons/cg";

import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import { update } from "lodash";

// const useSortableData = (items, config = null) => {
//   const [sortConfig, setSortConfig] = React.useState(config);

//   const sortedItems = React.useMemo(() => {
//     let sortableItems = [...items];
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === "ascending" ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableItems;
//   }, [items, sortConfig]);

//   const requestSort = (key, direction) => {
//     console.log(key, direction);
//     setSortConfig({ key, direction });
//   };

//   // console.log(sortedItems);

//   return { items: sortedItems, requestSort, sortConfig };
// };

export default function EnhancedTable() {
  // const { items, requestSort, sortConfig } = useSortableData(tempData);
  const [items , setItems] = useState(tempData);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  let indexOfLastRow = currentPage * rowsPerPage;
  let indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const [currentRows, setCurrentRows] = useState(items.slice(indexOfFirstRow, indexOfLastRow));
  const [changes, setChanges] = useState(0);

  const requestSort = (key, direction) => {
    let Items = cloneDeep(items);
    Items.sort((a, b) => { if (a[key] < b[key]) { return direction === "ascending" ? -1 : 1;
    } else if (a[key] > b[key]) { return direction === "ascending" ? 1 : -1;
    } else { return 0; }
  });
    setItems(Items);
  }

  const updatePage = (update) => {
    indexOfLastRow = rowsPerPage * (currentPage + update);
    indexOfFirstRow = indexOfLastRow - rowsPerPage;
    setCurrentPage(currentPage + update);
    setCurrentRows(items.slice(indexOfFirstRow, indexOfLastRow));
  };

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      let data = 
        items
          .filter((item) => item.name.toLowerCase().includes(query))
          .slice(indexOfFirstRow, indexOfLastRow);
      if (data.length == 0)
        data = items
            .filter((item) => item.email.toLowerCase().includes(query))
            .slice(indexOfFirstRow, indexOfLastRow);
      // console.log(data);
      setCurrentRows(data);
    }, 400)
  );

  const checkboxhandler = (idx) => {
    if(currentRows[idx].selected){
      setChanges(changes - 1);
    }
    else{
      setChanges(changes + 1);
    }
    items[idx + indexOfFirstRow].selected = !items[idx + indexOfFirstRow].selected;
    setItems([...items]);
  }

  const deleteHandler = () => {
    let newItems = cloneDeep(items);
    newItems = newItems.filter((item) => !item.selected);
    setItems(newItems);
    setChanges(0);
    updatePage(0);
  }

  React.useEffect(() => {
    setCurrentRows(items.slice(indexOfFirstRow, indexOfLastRow));
  }, [items]);

  React.useEffect(() => {
    if (!searchQuery) {
      setCurrentPage(1);
    } else {
      searchData.current(searchQuery);
    }
  }, [searchQuery]);

  return (
    <div className="employee-table">
      <div className="search-bar">
        <CgSearch style={{ color: "#C1C4C9", fontSize: "1.5rem" }} />
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search employee by name or email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="details-row">
        <div className="pages-detail">
          Showing{" "}
          <b>
            {indexOfFirstRow + 1}-
            {indexOfLastRow > items.length ? items.length : indexOfLastRow}
          </b>{" "}
          of <b>{items.length}</b>
        </div>
        <div className="btn-container">
            <span onClick={() => deleteHandler()}>
                Delete
                <img src="/newassets/bin.svg" alt="" />
              </span>
              <span>
                Edit
                <img src="/newassets/pencil.svg" alt="" />
              </span>
        </div>
      </div>
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th>
              <div className="table-head"></div>
            </th>
            {columnData[0].colList.map((item) => (
              <th>
                <div className="table-head">
                  {item.name}
                  {item.sortable && (
                    <span className="icon-box">
                      <AiFillCaretUp
                        className="icon"
                        onClick={() => {
                          requestSort(item.key, "ascending");
                          console.log(items);
                          setCurrentRows(items.slice(indexOfFirstRow, indexOfLastRow)
                          );
                        }}
                      />
                      <AiFillCaretDown
                        className="icon"
                        onClick={() => {
                          requestSort(item.key, "descending");
                          console.log(items);
                          setCurrentRows(items.slice(indexOfFirstRow, indexOfLastRow)
                          );
                        }}
                      />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((item, idx) => {
            return (
              <tr>
                <td>
                  <div className="table-cell">
                    <input type="checkbox" name={item.name} id="" checked={item.selected} onClick= {()=> checkboxhandler(idx)}/>
                  </div>
                </td>
                <td>
                  <div className="table-cell">{item.name}</div>
                </td>
                <td>
                  <div className="table-cell">{item.email}</div>
                </td>
                <td>
                  <div className="table-cell">{item.credits}</div>
                </td>
                <td>
                  <div className="table-cell">
                    {item.access.length > 1 ? (
                      <ul>
                        {item.access.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ul>
                    ) : (
                      item.access
                    )}
                  </div>
                </td>
                <td>
                  <div className="table-cell">{item.group}</div>
                </td>
                <td>
                  <div className="table-cell">{item.role}</div>
                </td>
                <td>
                  <div className="table-cell">{item.addOns}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-row">
        <div className="col">
          <a href="#">Export CSV file</a>
        </div>
        <div className="col">
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={items.length}
            currentPage={currentPage}
            updatePage={updatePage}
          />
        </div>
        <div className="col">
          Results Per Page
          <select
            name="rowsPerPage"
            id=""
            onChange={(e) => {
              const newRows = e.target.value;
              setRowsPerPage(newRows);
              setCurrentPage(1);
              indexOfLastRow = newRows * 1;
              indexOfFirstRow = indexOfLastRow - newRows;
              setCurrentRows(items.slice(indexOfFirstRow, indexOfLastRow));
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
      </div>
    </div>
  );
}
