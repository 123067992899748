import React, {useState} from 'react'
import './signin.css'

export default function Login() {

    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setData({ ...data, [name]: value });
    }
    const [record, setRecord] = useState([]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const newRecord = { ...data, id: new Date().getTime.toString() }
        setRecord([...record, newRecord]);
        setData({ email: "", password: "" });
    }
  return (
    <div className='main-container'>
        <div className="login-card">
            <h4>Login</h4>
            <p>Manage your employees, billings, memberships and access other benefits.</p>
            <form action="" autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-group">
                    <img src='/newassets/avatar.svg' />
                    {/* <BiUser /> */}
                    <input type="email" required name="email" id="" onChange={handleInput} value={data.name} placeholder='Enter Email'/>
                </div>
                <div className="form-group">
                    <img src='/newassets/key.svg' />
                    <input type="password" required name="password" id="" onChange={handleInput} value={data.password} placeholder='Enter Password'/>
                </div>
                <p><a href="#">Forgot password?</a></p>
                <button type="submit">Login</button>
            </form>
            <p className='end'>Don't have an account? <a href="/login">Sign Up</a></p>
        </div>
    </div>
  )
}
