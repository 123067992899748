export const data = [
    {
        link: "https://www.figma.com/file/5GVNojd5CFHXEeKf3e04sX/U",
        date: "20-06-2022",
        status: "Completed"
    },
    {
        link: "https://www.figma.com/file/5GVNojd5CFHXEeKf3e04sX/U",
        date: "20-06-2022",
        status: "Accepted"
    },
    {
        link: "https://www.figma.com/file/5GVNojd5CFHXEeKf3e04sX/U",
        date: "20-06-2022",
        status: "Failed"
    },
    {
        link: "https://www.figma.com/file/5GVNojd5CFHXEeKf3e04sX/U",
        date: "20-06-2022",
        status: "Accepted"
    },
]