import React, { useState } from 'react';
import { IoCloseSharp, IoCheckmarkOutline } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";
import { useLinkClickHandler } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input'; 

const capitalize = str => {
    const strList = str.toLowerCase().split('_');
    for (var i = 0; i < strList.length; i++) {
        strList[i] = strList[i].charAt(0).toUpperCase() + strList[i].substring(1);
    }
    return strList.join(' ');
};

function Dropdown(props) {
    const [active, setactive] = useState(props.data[0].displayName);
    function handler(e) {
        setactive(e);
    }
    const handleBlur = () => {
        console.log("handleblur");
      };
    return (
        <div className="CustomDropdown">
            <div className="dropdown">
                <IoCloseSharp className="CloseIcond" onClick={() => props.close()} />
                <div className="FIRST_ROW">
                    <h5 className="Filters">Filters</h5>
                    <h5 className="Reset">Reset</h5>
                </div>
                <div className='CARD'>
                    <div className="left">
                        {
                            props.data.map((filter) => {
                                return (
                                    <div className="filters" onClick={() => handler(filter.displayName)}>
                                        <h5 className={active == filter.displayName ? "active" : "notactive"}>{filter.displayName}</h5>
                                    </div>
                                )
                            })}
                        <div className="LAST_ROW">
                            <h5>Apply</h5>
                        </div>
                    </div>
                    <div className="right">
                        {
                            props.data.map((filter) => {
                                return (
                                    <div className="filters">
                                        {active == filter.displayName ? 
                                        filter.type == "CONTINUOUS" ? 
                                        <div className="filter_value">
                                        <Slider
                                        aria-label="Always visible"
                                        defaultValue={filter.active==null? filter.minValue : filter.active}
                                        step={10}
                                        marks={null}
                                        min = {filter.minValue}
                                        max = {filter.maxValue}
                                        color='secondary'
                                        className="slider"
                                        />
                                        <MuiInput
                                        className="input"
                                        value={filter.active==null? filter.minValue : filter.active}
                                        size="medium"
                                        onBlur={handleBlur}
                                        inputProps={{
                                            steps:1,
                                            min: filter.minValue,
                                            max: filter.maxValue,
                                            type: 'number',
                                            'aria-labelledby': 'input-slider',
                                        }}
                                        />
                                        </div> :<ul>
                                            {
                                                filter.values.map((value) => {
                                                    return (
                                                        <li>{value.active ? <div className="filter_value active"><p>{capitalize(value.name)}</p><BsCheckLg className='check-icon' /></div> : <div className="filter_value"><p>{capitalize(value.name)}</p></div >}</li>
                                                    )
                                                })
                                            }
                                        </ul>  : null}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dropdown;