import React from 'react'

export default function Section1() {
  return (
    <div className='about_section'>
        <div className="about_col">
            <h1>Reimagining the way users look for office space.</h1>
        </div>
        <div className="about_col">
            <img src="/newassets/aboutUs/aboutUs_bg.png" alt="" />
        </div>
    </div>
  )
}
