import React from 'react';
import ListingCard from './ListingCard';


function Section2(props) {
  return (
    <div className='listings_section2'>
        <div className='Card row'>
        {
          props.data.map((listing) => {
            return (
              <ListingCard data={listing} />
            )
          })
        }
        </div>
        
    </div>
  )
}

export default Section2