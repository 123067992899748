import React from 'react'
import AnalyticsBox from './AnalyticsBox'
import './analytics.css'
import BookedSpacesTable from './Table';
import Billing from './Billing';
import Visits from './Visits';
import NewTable from './NewTable';

export default function Analytics() {
  const temp = [
    {
      title: "Your Booked Spaces",
      component: BookedSpacesTable
    }
  ]
  return (
    <div className='panel-main analytics'>
      <div className ="Customrow Row" >
        <div className='Col'>
          <Visits />
        </div>
        <div className='Col'>
          <Billing />
        </div>
      </div>
      <div className="Row">
        <AnalyticsBox data = {temp[0]} />
      </div>
      {/* <div className='row'>
          <NewTable />
      </div> */}
    </div>
  )
}
