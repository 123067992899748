import React from 'react'
import Section1 from './Section1'
import './privacypolicy.css'
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';

export default function PrivacyPolicy() {
  return (
    <div>
        <Section1/>
        <Section6/>
        <Section7/>
    </div>
  )
}
