import React, { useState,useEffect } from "react";
import "./navbar.css";
import {IoClose} from "react-icons/io5";
import {AiOutlineMenu} from "react-icons/ai";
import data from "../Home/locations.json";

export default function Navbar() {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => setClicked(!clicked);
  const [path,setpath] = useState(window.location.pathname);
  return (
    <nav id="nav">
      <a href="/">
        <img
          src={ process.env.PUBLIC_URL + "/assets/white-logo.png"}
          className="navbar-logo"
          alt="MAIN-LOGO"
        />
      </a>
      <div className="ham__menu" onClick={handleClick}>
        {clicked ? (
          <IoClose className="ham_menu" />
        ) : (
          <AiOutlineMenu className="ham_menu" />
        )}
      </div>
      <ul className={clicked ? "nav_list active" : "nav_list"}>
        {path === "/" ? null : <li className="dropdown show">
          <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Locations</a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              {data.response.filters[0].values.map(city => {
                return (
                <a className="dropdown-item" href={"/Listings/" + city.name}>
                  <img className="logo" src={city.icon} alt="CityName"/>
                  <p>{city.name}</p>
                  </a>)
              })}
          </div>
          </li>}
        <li><a href="/about"> About Us</a></li>
        <li><a href="/contact">Contact Us</a></li>
        {path !== '/dashboard' ? null : <li><a className="user-icon" href="/dashboard"><img src="/newassets/navbar/setting.png"/></a></li>}
        {path !== '/dashboard' ? null : <li><a className="user-icon" href="/dashboard"><img src="/newassets/navbar/avatar.png"/></a></li>}
      </ul>
      
    </nav>
  );
}
